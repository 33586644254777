import { useEffect } from 'react';
import scrollama from "scrollama";
import ReactMarkdown from 'react-markdown'

import './App.css';
// import mySvg from './portrait.svg';

import { intro, footerText, codaText, staggerStick1, staggerExplain1, staggerStick2, staggerExplain2a, staggerExplain2b, staggerExplain2c, staggerStick3, staggerExplain3a, staggerExplain3b, staggerExplain3c, staggerStick4, staggerExplain4, staggerStick5, staggerExplain5a, staggerExplain5b, staggerExplain5c } from './content'

// instantiate the scrollama
const scroller = scrollama();

function App() {

  useEffect(() => {
    // setup scroller instance, pass callback functions
    scroller.setup({
      step: ".step",
      debug: false
    })
    .onStepEnter((response) => {
      // { element, index, direction }
      response.element.classList.add("step-active")

      let toc = document.getElementById('toc').getElementsByTagName('li');

      for (var y = 0, l = toc.length; y < l; y++) {
        toc[y].classList.remove('current-tab')
      }

      toc[response.index].classList.add('current-tab')


    })
    .onStepExit((response) => {
      // { element, index, direction }
      response.element.classList.remove("step-active")
    });

    // make sure sticky isn't getting nulled by an overflow
    let parent = document.querySelector('.sticky').parentElement;

    while (parent) {
        const hasOverflow = getComputedStyle(parent).overflow;
        if (hasOverflow !== 'visible') {
            console.log(hasOverflow, parent);
        }
        parent = parent.parentElement;
    }

  });

  return (
    <div className="App bg-ettio-700 pb-8">

      <div className="step" data-step="z" id="profile">
        <div className='fixed top-0 max-w-4xl w-full mx-auto bg-opacity-95 z-50 pt-1 pl-5 bg-ettio-900 text-sm h-7'>
          <div>Benjamin Wade Lundquist <span className="hidden sm:inline">| benjamin@ettio.com | 551-388-6763</span></div>
        </div>

        <div className="block-intro">
          <div className="w-full">
            <div className="max-w-xl">
              <div className="headline">
                <h3>Benjamin Lundquist</h3>
              </div>
              <ReactMarkdown children={intro} />
            </div>
          </div>
        </div>

      </div>

      <div id="toc" className="step-toc" data-step="a">
          <ul>
            <li><a href="#profile">Profile</a></li>
            <li><a href="#experience">Experience</a></li>
            <li><a href="#skills">Skills</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
      </div>

      <div className="step step1" data-step="a" id="experience">

        <div className="scrolly scrolly-1">
          <figure>
            <ReactMarkdown children={staggerStick1} />
          </figure>
          <article>
            <ReactMarkdown children={staggerExplain1} />
          </article>
        </div>

        <div className="scrolly scrolly-2">
          <figure className='sticky'>
            <ReactMarkdown children={staggerStick2} />
          </figure>
          <article>
            <ReactMarkdown children={staggerExplain2a} />
            <ReactMarkdown children={staggerExplain2c} />
            <div className="logos">
              <h6 className="text-center">Select Projects</h6>
              <ReactMarkdown children={staggerExplain2b} />
            </div>
          </article>
        </div> 		

        <div className="scrolly scrolly-3">
          <figure className='sticky'>
            <ReactMarkdown children={staggerStick3} />
          </figure>
          <article>
            <ReactMarkdown children={staggerExplain3a} />
            <ReactMarkdown children={staggerExplain3c} />
            <div className="logos">
              <h6 className="text-center">Select Clients</h6>
              <ReactMarkdown children={staggerExplain3b} />
            </div>

          </article>
        </div>

        <div className="scrolly scrolly-4">
          <figure className='sticky'>
            <ReactMarkdown children={staggerStick4} />
          </figure>
          <article>
            <ReactMarkdown children={staggerExplain4} />
          </article>
        </div>

        <div className="scrolly scrolly-5">
          <figure className='sticky'>
            <ReactMarkdown children={staggerStick5} />
          </figure>
          <article>
            <ReactMarkdown children={staggerExplain5a} />
            <ReactMarkdown children={staggerExplain5c} />
            <div className="logos">
              <h6 className="text-center">Select Projects</h6>
              <ReactMarkdown children={staggerExplain5b} />
            </div>
          </article>
        </div>


      </div>

      <div className="step step2 relative step-skills" data-step="b" id="skills">
        <div className="p-5 z-10">
          <ReactMarkdown children={codaText} />
        </div>
      </div>

      {/* <div className="step step-pdf step3 relative" data-step="c" id="contact">
        <div className="p-5 z-10">
          <ReactMarkdown children={contactText} />
        </div>
        <video className="absolute top-0 w-full h-full object-cover z-0"   src="/pexels-arvin-latifi-6466763.mp4" autoPlay loop muted playsInline></video>
      </div> */}

      <div className="step step-footer" data-step="d">
        <ReactMarkdown children={footerText} />
      </div>

    </div>
  )
}

export default App;
